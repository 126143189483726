.PrivacyPolicyContainer {
  background-color: white;
  height: 100vh;
}

.Container {
  background-color: white;
  height: fit-content;
  max-width: 90%;
  margin: 10px auto;
  padding: 20px;
}
