
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  position: relative;
  z-index: 2;
}

.logo img {
  max-height: 50px;
  max-width: 60%;
}

.navbar {
  display: flex;
}

.NavLink {
  position: relative;
  margin-left: 20px;
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease, transform 0.1s ease;
}

.NavLink:hover {
  color: red;
  transform: scale(1.05);
}

.menuIcon {
  display: none; /* Initially hide the hamburger icon */
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #333;
  }

  .navbar.show {
    display: flex;
  }

  .NavLink {
    margin: 10px;
    text-indent: 5vw;

  }

  .menuIcon {
    display: flex; /* Show the hamburger icon on mobile screens */
  }
}
