.InfoContainer {
  width: 80vw;
  margin: 0 auto;
  background-color: #353535ea;
  /* border-radius: 15px; */
  padding: 20px;
}

.Header{
  text-align: center;
  font-weight: bold;
  color: #ffffff;
}
.InfoContainer h3 {
  color: #ff0000;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.InfoSection{
  margin: 0 auto;
}

.InfoContainer p {
  color: #ffffff;
  line-height: 1.6;
}


.InfoSection h5 {
  color: #ff5733;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.InfoSection p {
  color: #ffffff;
  text-align: left;
  margin-top: 10px;
  text-align: center;
}

 @media screen and (max-width: 600px) {
  .InfoContainer {
    width: 100%;
    padding: 0px;
  }
   .InfoSection {
    width: 80%;
    padding: 10px;
  } 
 }
