/* CasinoList.module.css */

.casinoList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  width: 80vw;
  margin: 0 auto;
  margin-top: 1px;
  background-color: #353535ea;
  /* border-radius: 15px; */
  padding: 20px;
}

.IntroContainer {
  border-radius: 15px;
  padding: 20px;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

.MainHeader {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
  margin: 0 auto;
}

.IntroText {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 20px;
  line-height: 1.5;
}

.itemContainer {
  color: black;
  border: 4px solid rgb(202, 198, 198);
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  margin: 10px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  position: relative;
  transition: border-color 0.4s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.itemContainer:hover {
  border-color: #ff3131;
}

.LogoContainer {
  display: flex;
  width: 20%;
  justify-content: center;
}

.LogoContainer img {
  width: 100%;
  max-width: 120px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.LogoContainer img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.TextContainer,
.GradeContainer,
.DepositContainer {
  width: 25%;
  margin-right: 10px;
  text-align: center;
}

.casinoButton {
  background-color: #ff3131;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  width: 60%;
  margin: auto;
  z-index: 1;
  transition: background-color 0.3s ease;
}

.casinoButton:hover {
  background-color: #db251e;
}

.DepositContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GradeContainer {
  transition: transform 0.5s;
}

.TextSpan {
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.GradeSpan {
  font-weight: bold;
  font-size: 35px;
  color: #ffd700;
  margin-bottom: 8px;
}

.DepositSpan {
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.pulseAnimation {
  transform: scale(1.1);
}

.bottom {
  margin-top: 20px;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
}

.bottom span {
  color: #fff;
  margin: 2px;
  transition: text-decoration 0.1s;
}

.bottom span::after {
  content: "|";
  color: #fff;
  margin: 5px;
}

/* Responsive Styles */

@media screen and (max-width: 600px) {
  .casinoList {
    width: 100%;
    background-color: #353535ea;
    padding: 0px;
  }
  .itemContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 70%;
  }
  .itemContainer .LogoContainer,
  .itemContainer .TextContainer,
  .itemContainer .GradeContainer,
  .itemContainer .DepositContainer {
    margin-bottom: 10px;
  }
  .LogoContainer{
    width: 100%;
  }
  .LogoContainer img {
    height: auto;
    margin-bottom: 5px;
    max-width: 100%;
    width: 35vw;;
  }

  .TextContainer,
  .GradeContainer,
  .DepositContainer {
    width: 100%;
    margin-right: 0;
  }

  .casinoButton {
    width: 100%;
    margin-bottom: 10px;
  }
  .pulseAnimation {
    transform: scale(1.1);
  }

  .IntroText {
    padding: 4px;
    display: none;
  }

  .bottom {
    display: flex;
    flex-direction: column;
  }
  .bottom span {
    color: #fff;
    font-size: 14px;
  }
  .bottom span::after {
    content: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .itemContainer {
    width: 80%;
  }

  .casinoButton {
    width: 100%;
  }
  .bottom {
    display: flex;
    flex-direction: column;
  }
  .bottom span::after {
    content: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .itemContainer {
    width: 80%;
  }
  .casinoButton {
    width: 100%;
  }
  .bottom {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .itemContainer {
    width: 80%;
  }
  .casinoButton {
    width: 100%;
  }
}
