.faq {
  width: 80vw;
  margin: 0 auto;
  padding: 20px;
  /* border-radius: 15px; */
  background-color: #353535ea;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ListWrapper{
  width: 80%;
}

.heading {
  color: #ffffff;
  text-align: center;
}

.list {
  list-style-type: none;
  padding: 0;
}

.question {
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  color: #ffffff;
  transition: background-color 0.6s;
}

.question:hover {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.question.active {
  background-color: #eeeded34;
}

.question h3 {
  color: #ffffff;
  transition: color 0.2s ease;
}

.answer {
  font-weight: 700;
  color: #ffffff;
}

/* For screens up to 600px */
@media screen and (max-width: 600px) {
  .faq {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* .list > li {
    width:70%;
  } */
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .faq {
    width: 80vw;
  }
}


