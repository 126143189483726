
.FooterContainer {
  display: flex;
  flex-direction: column; 
  background-color: #333;
  color: #fff;
}

.LeftDiv,
.RightDiv {
  flex: 1;
  text-align: center; 
}

.RightDiv {
  margin-top: 10px; 
}

.LeftDiv p,
.RightDiv p {
  margin: 0 auto;
}

.RightDiv {
  text-align: center; 
  margin-top: 10px; 
}

.LeftDiv p {
  font-weight: bold;
}

.RightDiv a {
  color: #fff;
  text-decoration: none;
  margin-right: 15px;
  font-size: 14px;
}

.RightDiv a:hover {
  text-decoration: underline;
}

.FooterLink {
  transition: color 0.3s ease;
}

.FooterLink:hover {
  color: #FA2280;
}


@media screen and (min-width: 601px) and (max-width: 768px) {
  .FooterContainer {
    flex-direction: column; 
  }

  .RightDiv {
    margin-top: 0; 
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .FooterContainer {
    padding: 10px 40px; 
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .FooterContainer {
    padding: 10px 60px; 
  }
}

@media screen and (min-width: 1201px) {
  .FooterContainer {
    padding: 10px 80px; 
  }
}
