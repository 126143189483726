.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.contactForm {
  width: 100%;
  max-width: 600px; /* Adjusted to accommodate longer text */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 90px;
}



.formTitle {
  text-align: center;
  margin-bottom: 16px;
  color: #333;
}

.introText {
  margin-bottom: 20px; /* Added space below the intro text */
  color: #555;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  color: #333;
}

.input,
.textarea {
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease-in-out;
}

.input:focus,
.textarea:focus {
  border-color: #4caf50;
}

.textarea {
  resize: vertical;
  min-height: 200px;
}

.submitButton {
  background-color: #4caf50;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.submitButton:hover {
  background-color: #45a049;
}

/* Responsive Styles */
@media screen and (max-width: 600px) {
  .contactForm {
    max-width: 100%;
  }

  .formTitle {
    font-size: 24px;
  }

  .introText {
    font-size: 14px;
  }

  .input,
  .textarea {
    font-size: 16px;
  }

  .submitButton {
    font-size: 18px;
  }
}
